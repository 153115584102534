export const VALID_GUESSES = [
  'goblet',
  'george',
  'dean',
  'beater',
  'riddle',
  'turner',
  'owls',
  'stan',
  'wizard',
  'azkaban',
  'skeeter',
  'alastor',
  'keeper',
  'prophet',
  'dark',
  'luck',
  'cadogan',
  'erised',
  'viktor',
  'rubeus',
  'bell',
  'bertie',
  'lucius',
  'pomfrey',
  'prince',
  'centaur',
  'willow',
  'scar',
  'phoenix',
  'dungeon',
  'creevey',
  'forest',
  'floo',
  'hagrid',
  'hollow',
  'malfoy',
  'nimbus',
  'muggle',
  'knox',
  'knight',
  'seeker',
  'fred',
  'time',
  'mirror',
  'cedric',
  'hedwig',
  'thomas',
  'charlie',
  'fawkes',
  'hallows',
  'hogs',
  'defense',
  'burrow',
  'oliver',
  'chamber',
  'kadavra',
  'deathly',
  'neville',
  'sirius',
  'krum',
  'aragog',
  'chaser',
  'dragon',
  'seamus',
  'myrtle',
  'borgin',
  'blotts',
  'expecto',
  'london',
  'crucio',
  'potter',
  'wand',
  'flamel',
  'boggart',
  'always',
  'secrets',
  'norbert',
  'diagon',
  'sprout',
  'unicorn',
  'potion',
  'crabbe',
  'crouch',
  'zonkos',
  'mungos',
  'wood',
  'bezoar',
  'pius',
  'norris',
  'grim',
  'lady',
  'godric',
  'padfoot',
  'yule',
  'granger',
  'gold',
  'bludger',
  'petunia',
  'peeves',
  'ludo',
  'horcrux',
  'diggory',
  'dudley',
  'prongs',
  'howler',
  'dolores',
  'half',
  'burkes',
  'newt',
  'sorting',
  'nagini',
  'vernon',
  'weasley',
  'room',
  'eaters',
  'knut',
  'glasses',
  'love',
  'privet',
  'sickle',
  'bagshot',
  'castle',
  'nick',
  'toad',
  'luna',
  'minerva',
  'rita',
  'severus',
  'bagman',
  'snitch',
  'carrow',
  'ariana',
  'dursley',
  'marvolo',
  'bill',
  'alchemy',
  'skrewt',
  'fluffy',
  'imperio',
  'quaffle',
]
