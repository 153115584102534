export const HINTS = [
  'Impartial judge for the Triwizard Tournament',
  'Beater',
  'Chaser',
  'Quidditch',
  'Enigma',
  'During the Battle of the Department of Mysteries, the Ministrys entire stockpile were destroyed',
  'Test',
  'Conductor of the Knight Bus',
  '🧙‍♂️',
  'Dementors',
  '🐞',
  '👁️',
  'Quidditch',
  '🗞️',
  'Mark',
  'Felix Felicis',
  'Knight',
  '"deepest, most desperate desire of our hearts."',
  'Played in the final of the Quidditch World Cup',
  'Care of Magical Creatures Professor',
  'Chaser',
  'Candy',
  'Death Eater',
  '🏥',
  '🤴',
  'Bane',
  'Entrance to the Shrieking Shack',
  '⚡',
  'Magical Creature',
  'Potions Classroom Location',
  '📸',
  'Forbidden',
  '🔥',
  'Care of Magical Creatures Professor',
  'Birthplace of Godric Gryffindor',
  'Pure Blood Family',
  '🧹',
  'No-Maj',
  'Lumos',
  'Sir',
  'Quidditch',
  'Beater',
  'Turner',
  '"deepest, most desperate desire of our hearts."',
  'Son of Amos',
  '🦉',
  'Chaser for his houses Quidditch team',
  'Prefect and Quidditch Captain',
  'His tail feathers were the cores of the two twin wands',
  'Elder Wand for example',
  'Pub in Hogsmeade',
  'Class where no teacher could hold the post for more than a year without suffering an unpleasant fate',
  '🏠',
  'Keeper and Captain for the Gryffindor Quidditch team',
  'Secrets',
  'Unforgivable Curse',
  'Hallows',
  'Parents were tortured into insanity by Bellatrix Lestrange',
  '🐕',
  'Played in the final of the Quidditch World Cup',
  '🕷️',
  'Quidditch',
  '🐉',
  '☘️',
  '🚽',
  'Knockturn Alley',
  '📚',
  'Patronus charm',
  'Location of The Leaky Cauldron',
  'Unforgivable Curse',
  'Surname of an old wizarding family descended from Ignotus Peverell',
  'Ollivanders',
  'Sorcerers/Philosophers Stone',
  'Shapeshifter',
  'How Snape explains to Dumbledore in the final book why his Patronus takes the same shape of Lilys',
  'Chamber',
  'norbert',
  '🐉',
  'Herbology',
  'Magical Creature',
  '🧪',
  'Son of a Death Eater',
  'Barty',
  'Joke',
  '🏥',
  'Keeper',
  'Stone from the stomach of a goat',
  'Minister for Magic',
  '🐈',
  'Omen of Death',
  'Grey',
  'Founder of Hogwarts',
  '🐕',
  'Ball',
  'Minister for Magic',
  '💰',
  'Quidditch',
  'Muggle',
  'Poltergeist ',
  'Head of the Department of Magical Games and Sports',
  'An Object Containig Ones Soul',
  'Seeker',
  'Muggle',
  '🦌',
  '✉️',
  'Senior Undersecretary to the Minister for Magic',
  'Part of a book title',
  'Antique Shop',
  'Test',
  '🎩',
  '🐍',
  'Muggle',
  'Pure Blood Family',
  'Come and Go BLANK',
  'Death',
  '💰',
  'Integral Part of Harrys Look',
  'What protected Harry Against the Killing Curse',
  'Street',
  '💰',
  'Author of A History of Magic',
  'Hogwarts',
  '🟥👻',
  'Common Pet',
  'Her mother accidentally died while experimenting with spells',
  '🐈',
  '🐞',
  '"Always"',
  'Head of the Department of Magical Games and Sports',
  'Quidditch',
  'Pure Blood Wizarding Family',
  'An Obscurial',
  'Muggles',
  'Descendant of Salazar Slytherin',
  'Prefect and Head Boy',
  'Nicolas Flamel',
  'Magical hybrid beast',
  '🐶🐶🐶',
  'Unforgivable Curse',
  'Quidditch',
]
